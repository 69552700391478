import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    // Retorno de Opções (selects) para o formulário de cadastro de custos fixos
    async getFixedCostsOptionsByUnit({ commit }, companyCode) {
      try {
        const response = await api.get(
          `/flows/${companyCode}/data/managers/fixed-costs/options`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    // Retorno de Opções (selects) para o formulário de cadastro de custos variáveis
    async getVariableCostsByUnit({ commit }, companyCode) {
      try {
        const response = await api.get(
          `/flows/${companyCode}/data/managers/variable-costs/options`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getFixedCostsRegisteredsByUnit({ commit }, data) {
      try {
        // Encoda data no GET url
        const params = new URLSearchParams()
        params.append('stepId', data.stepId)
        params.append('formId', data.formId)
        params.append('formStepId', data.formStepId)

        const response = await api.get(
          `/flows/${
            data.companyCode
          }/data/managers/fixed-costs?${params.toString()}`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getVariableCostsRegisteredsByUnit({ commit }, data) {
      try {
        // Encoda data no GET url
        const params = new URLSearchParams()
        params.append('stepId', data.stepId)
        params.append('formId', data.formId)
        params.append('formStepId', data.formStepId)

        const response = await api.get(
          `/flows/${
            data.companyCode
          }/data/managers/variable-costs?${params.toString()}`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async saveFixedCostManager({ commit }, data) {
      try {
        const response = await api.post(
          `/flows/${data.companyCode}/data/managers/fixed-costs`,
          data.form,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
    async saveVariableCostManager({ commit }, data) {
      try {
        const response = await api.post(
          `/flows/${data.companyCode}/data/managers/variable-costs`,
          data.form,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
    async finishManagerRegistersForm({ commit }, data) {
      try {
        const response = await api.put(
          `/flows/${data.companyCode}/data/managers/finish`,
          data.form,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
    // Retorno de Opções (selects) para o formulário de cadastro de custos variáveis
    async getCollaboratorsWithRegister({ commit }, data) {
      try {
        // Encoda data no GET url
        const params = new URLSearchParams()
        params.append('stepId', data.stepId)
        params.append('formId', data.formId)
        params.append('formStepId', data.formStepId)

        const response = await api.get(
          `/flows/${
            data.companyCode
          }/data/managers/personal-costs?${params.toString()}`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getPersonalCostsParams({ commit }, companyCode) {
      try {
        const response = await api.get(
          `/flows/${companyCode}/data/managers/personal-costs/params`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async promoteCollaborator({ commit }, data) {
      try {
        const response = await api.post(
          `/flows/${data.companyCode}/data/managers/personal-costs/promoter`,
          data,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
    async processCollaboratorList({ commit }, data) {
      try {
        const response = await api.post(
          `/flows/${data.companyCode}/data/managers/personal-costs/process`,
          data,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
    async savePersonalCostsNewCollaborator({ commit }, data) {
      try {
        const response = await api.post(
          `/flows/${data.companyCode}/data/managers/personal-costs/new-collaborator`,
          data.form,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
    async savePersonalCostManager({ commit }, data) {
      try {
        const response = await api.post(
          `/flows/${data.companyCode}/data/managers/personal-costs/natures`,
          data.form,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },

    async removeCollaborator({ commit }, data) {
      try {
        const response = await api.post(
          `/flows/${data.companyCode}/data/managers/personal-costs/remove`,
          data,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
    async deleteFixedCostForm({ commit }, data) {
      try {
        const response = await api.delete(
          `/flows/${data.companyCode}/data/managers/fixed-costs/${data.id}`,
        )
        return response
      } catch (error) {
        throw error
      }
    },
    async deleteVariableCostForm({ commit }, data) {
      try {
        const response = await api.delete(
          `/flows/${data.companyCode}/data/managers/variable-costs/${data.id}`,
        )
        return response
      } catch (error) {
        throw error
      }
    },

    async getPersonalCostsNaturesByUnit({ commit }, data) {
      try {
        const response = await api.get(
          `/flows/${data}/data/managers/personal-costs/natures`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getPersonalCostsNatureRegisterd({ commit }, data) {
      try {
        const params = new URLSearchParams()
        params.append('stepId', data.stepId)
        params.append('formId', data.formId)
        params.append('formStepId', data.formStepId)

        const response = await api.get(
          `/flows/${
            data.companyCode
          }/data/managers/personal-costs/registereds?${params.toString()}`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async updateFormPersonalCostManager({ commit }, data) {
      try {
        const response = await api.put(
          `/flows/${data.companyCode}/data/managers/personal-costs/registereds/${data.id}`,
          data.form,
        )
        return response
      } catch (error) {
        throw error
      }
    },
    async getPersonalCostsReport({ commit }, data) {
      try {
        // Encoda data no GET url
        const params = new URLSearchParams()
        params.append('stepId', data.stepId)
        params.append('formId', data.formId)
        params.append('formStepId', data.formStepId)

        const response = await api.get(
          `/flows/${
            data.companyCode
          }/data/managers/personal-costs/reports?${params.toString()}`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
